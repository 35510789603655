@font-face {
    font-family: 'Heldane Display';
    src: url('HeldaneDisplay-Medium.ttf') format('truetype'),
        url('HeldaneDisplay-Medium.woff') format('woff'),
        url('HeldaneDisplay-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Heldane Display';
    src: url('HeldaneDisplay-Bold.ttf') format('truetype'),
        url('HeldaneDisplay-Bold.woff') format('woff'),
        url('HeldaneDisplay-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}