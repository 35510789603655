body{
  min-height:100vh;
  
}

html{ scrollbar-width: none; -ms-overflow-style: none;}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body[data-page="home"],
body[data-page="about"],
body[data-page="about-investment-landscape"],
body[data-page="management-advisory-board"]{
  background-image: url(assets/img/background.svg);
  background-position: right bottom ;
  background-repeat: no-repeat;
  background-size: 53.063rem;
}

body[data-page="home"],
body[data-page="about"],
body[data-page="management-advisory-board"]{
  background-position: 40.625rem 18.313rem;
}

body[data-page="about-investment-landscape"],
body[data-page="contact"]{
  background-position: -19.625rem 34.313rem;
}


.container {
  position: relative;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
  padding-top: 25px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

header{ height:5.313rem; }

header a.logo{ display:block; margin-top:0.625rem; }
header a.logo img{ display:block; width: 12.625rem; }

header.mobile{ height:auto; }
header.mobile a.logo img{ width:110px; }
header.mobile .btn { padding:0.3rem 0.5rem; font-size:1.3rem; border-radius:2px; line-height: normal; margin:0.5rem 0 0.5rem 0; }

.navbar-expand .navbar-nav .nav-link{ height:5.313rem; font-weight: 600; }
.navbar-expand .navbar-nav .nav-link span,
.navbar-expand .navbar-nav .nav-link.active span{ display:block; padding-bottom:0.313rem; }

header.mobile div[data-show]{ display: none; }
header.mobile div[data-show="true"]{ display: block; }

header.mobile .navbar-expand .navbar-nav .nav-link{ height:auto; font-size: 1rem; color: #fff; }
header.mobile .navbar{ display: block; }
header.mobile .navbar-nav{ display: block; }
header.mobile .navbar-expand .navbar-nav .nav-link span,
header.mobile .navbar-expand .navbar-nav .nav-link.active span{ padding-bottom:0; }

header.mobile .navbar-expand .navbar-nav .nav-link,
header.mobile .nav-mobile-vertical-link-container > a,
header.mobile .nav-mobile-horizontal-link-container > a{ display:block; padding:0.5rem 0.7rem; border-bottom:1px solid rgba(196, 196, 196, 0.2) !important; }

header.mobile .nav-mobile-vertical-link-container > a{ padding-left:1.5rem; color: #B7B8B8; }
header.mobile .nav-mobile-horizontal-link-container > a{ padding-left:3rem; color: #B7B8B8; }

header.mobile .navbar-expand .navbar-nav .nav-link-container:hover .nav-link span,
header.mobile .navbar-expand .navbar-nav .nav-link.active span{ border-bottom: 0 none; }

.welcome-container{
  max-width:37.5rem;
}

.welcome-container p{ max-width: 31.25rem; }

.welcome-container .btn-toolbar {
    margin-top: 2.5rem;
}

.welcome-container .btn-toolbar .btn{ min-width:12.5rem; }

.principal-container{
  max-width:30rem;
}

.principal-container-expanded{
  max-width:60rem;
}

.principal-photo{ max-width:26.813rem; display: inline-block; margin-right:4rem; }

footer a:not(.image){ font-size: 0.7rem; text-transform: uppercase; letter-spacing: 0.1rem; position: relative; margin-left: 4.5rem; }
footer a.image img{ width:1.125rem; }
footer a span{ position:absolute; left:-4.25rem; top:-0.188rem; }
footer a:not(.image)::after{ content:""; position: absolute; background-color:#C4C4C4; width: 2.375rem; height:0.063rem; top:0.375rem; left:-3rem;  }

.about-gallery{ float:right; width: 100%; }
.about-gallery img{ width: 100%; display: block; }

.nav-link-container{ position: relative; }
.nav-link-container.expandable > a > span{ padding-right: 0.9rem; }
.nav-link-container.expandable > a > span{ background:right 0.688rem no-repeat url(assets/img/nav-arrowdown.svg); background-size: 0.438rem 0.25rem; }
.nav-link-container.expandable:hover > a > span{ background-image: url(assets/img/nav-arrowdown-white.svg); background-size: 0.438rem 0.25rem; }

.nav-vertical{ position: absolute; top:5.313rem; z-index: 100; min-width:12.5rem; display: none; }
.nav-link-container:hover .nav-vertical{ display: block; }
.nav-vertical-link-container{ position: relative; }
.nav-vertical-link-container a{ display:block; padding:1rem 1rem; }
.nav-vertical-link-container.expandable > a > span{ padding-right: 0.9rem; }
.nav-vertical-link-container.expandable > a > span{ background:right 0.313rem no-repeat url(assets/img/nav-arrowright.svg); background-size: 0.25rem 0.438rem; }
.nav-vertical-link-container.expandable:hover > a > span{ background-image:url(assets/img/nav-arrowright-white.svg); background-size: 0.25rem 0.438rem; }
.nav-vertical-link-container a:hover{ text-decoration: none; }

.nav-horizontal{ position: absolute; top:0; left: 12.5rem; z-index: 100; min-width:12.5rem; display: none; }
.nav-vertical-link-container:hover .nav-horizontal{ display: block; }

.person{
  background-size: cover;
  background-repeat:no-repeat;
  background-position: center center;
  position: relative;
}
.person p,
.person p strong:not(.accent){ color: #C4C4C5; }
.person p strong:not(.accent){ font-weight: 600; }

  .person .person-inner{
    background-size: cover;
    height:31.25rem;
    background: linear-gradient(180deg, rgba(42, 44, 45, 0) 56.01%, rgba(42, 44, 45, 0.803705) 83%, #2A2A2B 100%);
  }
  .person .person-inner h3{ margin-top:22rem; }
  .person:hover .person-inner h3,
    .person:hover .person-inner p{ opacity:1; transition: opacity 0.5s; }

  .person .person-details{
    transition: opacity 0.5s;
    background-color: rgba(49,52,53,.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity:0;
  }
  .person .person-details h3,
  .person .person-details p.subheader{ color:#ACA18E; }
  .person .person-details p.subheader{ border-bottom:0.063rem solid rgba(196,196,196,0.3); padding-bottom:0.688rem; }
  .person .person-details p,
  .person .person-details li,
  .person .person-details a{ font-size: 0.875rem; line-height: 1.375rem; }
  .person .person-details ul{ padding-left:0.9rem; margin-top: -1rem; }

  .person:hover .person-details{ opacity:1; }
  .person:hover .person-inner h3,
  .person:hover .person-inner p{ opacity:0; }

  .person .person-details .footer{ position: absolute; bottom: 0; left: 0; right: 0; }
  .person .person-details .footer a.read-more{ color: #ACA18E; display: inline-block; background: right center no-repeat url(assets/img/link-arrowright.svg); padding-right: 1.625rem; }

  .portfolio-item img{
    width:15rem;
  }

  .portfolio-item p{
    line-height: 1.375rem;
    font-size: 0.875rem;
  }

.tabs .nav-link{ font-size: 2.25rem; padding-left:0; margin-right:3rem; }
.tabs .nav-link:not(.active){ opacity: 0.2; }

.organization{ padding:2rem 0; transition: background-color 0.5s ease; }
.organization .image{
  background-position:center center;
  background-repeat: no-repeat;
  width: 10rem;
  height: 5.5rem;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
}

.investment-landscape-graph{
  background-repeat: no-repeat;
  background-size:contain;
  position: relative;
  display: block;
  width: 53.125rem;
  height: 38.125rem;
  margin: -10rem 0 0 19.875rem;
}

  .investment-landscape-graph p{ margin: 0; position: absolute; font-size: 0.875rem; width: 12.5rem; }
  .investment-landscape-graph p.label.label-realestatehospitality{ top: 13.875rem; left: 16.125rem; width:26.688rem; text-align: center; line-height: normal; }
  .investment-landscape-graph p.label.label-technology{ top:11.875rem; left: -5.625rem; }
  .investment-landscape-graph p.label.label-softwareit{ top:0.25rem; left: 7.188rem; }
  .investment-landscape-graph p.label.label-others{ top:-0.438; left: 40rem; }
  .investment-landscape-graph p.label.label-healthcare{ top:5.5rem; left: 49.125rem; }
  .investment-landscape-graph p.label.label-bankingfinancialservices{ top:20.5rem; left: 54rem; }
  .investment-landscape-graph p.label.label-foodbeverages{ top:29.438rem; left: 49rem; }
  .investment-landscape-graph p.label.label-energy{ top:31.625rem; left: 11.125rem; }

  .investment-landscape-graph p.label-realestatehospitality{ font-size: 2.3rem; }

.investment-locations-graph{
  background-repeat: no-repeat;
  background-size:contain;
  position: relative;
  display: block;
  width:95%;
  padding-top:80%;
  margin: 0 0 0 0;
}

.location-list{ margin-top:-11.25rem; }
.location-list .country-name{ font-weight:600; }

.location-types{ position: absolute; right: 0; top: -4.375rem; font-size: 0.875rem; font-weight: 600; color: #fff; }
.location-types span{ position: relative; padding:0 0 0 1.563rem; margin: 0 0 0 3.125rem; }
.location-types span::after{ position: absolute; content:""; left:0; top: 0.125rem; width:0.75rem; height: 0.75rem; border-radius: 100%; background-color: #fff; }
.location-types span.investments::after{  background-color: #ACA18E; }

header .btn,
.btn.btn-email{ padding:0.4rem 1.6rem; font-size:0.9rem; font-weight:600; }
.btn.btn-email{ background-image: url(assets/img/mail.svg); background-position:1.125rem 0.625rem; background-repeat: no-repeat; padding-left: 2.5rem; background-size: 1.063rem 1rem; }

footer{ margin-bottom:4rem; }

@media only screen and (min-width: 1500px) {
  html{ font-size:1.1vw; }

  .wrapper .container{ max-width: 80.563rem; }
}

@media only screen and (max-width: 1353px) {
  .investment-locations-graph{ width:100%; }
}

@media only screen and (min-width: 992px) {
  .page {
    padding-top: 85px;
  }

  .principal-container {
    padding-top: 4.063rem;
  }

  .welcome-container{
    padding-top:4.063rem;
  }

}

@media only screen and (max-width: 991px) {
  .person .person-details{ opacity: 1; }
  .tabs .nav-item .nav-link{ font-size: 1.5rem; }
  .welcome-container .btn-toolbar .btn{ min-width: 10rem; padding: .7rem .8rem;  }

  .investment-locations-graph{
    padding-top:90%;
  }
}

@media only screen and (max-width: 1200px) {
  .welcome-container,
  .welcome-container p{ max-width: none; }
}

@media only screen and (max-width: 1399px) {
  .portfolio-item img {
    width: 100%;
  }

  .investment-landscape-graph{
    width: 26.562rem;
    height: 19.062;
    margin: 0;
  }
  
    .investment-landscape-graph p{ margin: 0; position: absolute; font-size: 0.435rem; width: 12.5rem; }
    .investment-landscape-graph p.label.label-realestatehospitality{ top: 6.937rem; left: 8.062rem; width:13.344rem; text-align: center; line-height: normal; }
    .investment-landscape-graph p.label.label-technology{ top:5.937rem; left: -2.812rem; }
    .investment-landscape-graph p.label.label-softwareit{ top:0.125rem; left: 3.594rem; }
    .investment-landscape-graph p.label.label-others{ top:-0.285rem; left: 20rem; }
    .investment-landscape-graph p.label.label-healthcare{ top:2.75rem; left: 24.562rem; }
    .investment-landscape-graph p.label.label-bankingfinancialservices{ top:10.25rem; left: 27em; }
    .investment-landscape-graph p.label.label-foodbeverages{ top:14.719rem; left: 24.5rem; }
    .investment-landscape-graph p.label.label-energy{ top:15.812rem; left: 5.5625rem; }
  
    .investment-landscape-graph p.label-realestatehospitality{ font-size: 1.15rem; }
    
  .principal-photo {
    width: 100%;
    margin-right:0;
  }

  .tabs .nav-link{ padding:.5rem 1rem .5rem 0; }
}


