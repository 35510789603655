@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
@import url('./assets/fonts/HeldaneDisplay/font.css');

$text-grey-link: #B7B8B8;
$gold-main: #ACA18E;

$gold-hover: #a7a7a7;

$link-color: #fff;
$link-decoration: none;
$link-hover-decoration: underline;

$theme-primary: #4B4E4F;

$body-bg: #373A3B;
$body-color: #C4C4C5;

$font-family-sans-serif: "Libre Franklin", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-header-sans-serif: "Heldane Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-size-base: 1rem;
$line-height-base: 1.75;

$h1-font-size: 4.5rem;
$h1-font-color: #ffffff;
$h1-accent-font-color: $gold-main;

$h2-font-size: 2.25rem;

$h5-font-size: 1.5rem;

$h6-font-size: 1.125rem;
$h6-font-color: #ffffff;

$gray-300: #C4C4C4;

$navbar-padding-y: 0;
$navbar-light-color: $text-grey-link;

$nav-link-padding-y: 2rem;
$navbar-nav-link-padding-x: 2rem;
$navbar-light-hover-color: #ffffff;
$navbar-light-active-color: #ffffff;
$nav-link-font-size: 0.9rem;

$btn-border-radius: 1rem;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1353px
);

h1, h2, h3, h4, h5{ font-family: $font-family-header-sans-serif; color: $h1-font-color; }
h1 span{ color: $h1-accent-font-color; }
h2{ border-top: 0.063rem solid $h1-accent-font-color; }

h5{ border-bottom: 0.063rem solid $h1-accent-font-color; padding-bottom: .5rem; }
h6{ font-size: $h6-font-size; border-bottom: 0.063rem solid $h1-accent-font-color; padding-bottom: .5rem; color: $h6-font-color; font-weight: bold !important; }
div.h7{ font-size:1.25rem; font-weight: bold; color: $gold-main; margin:0.5rem 0 1rem; display: block; }

p strong{ color: $gold-main; }

p strong.accent{ color: #fff; font-weight: 600; }

.tabs .nav-link{ font-size: 2.25rem; font-family: $font-family-header-sans-serif; color: $gold-main; font-weight: normal; }

.investment-landscape-graph p.label-realestatehospitality{ color:$body-bg; font-weight: 600; }

.navbar-expand .navbar-nav .nav-link{ font-size:$nav-link-font-size; }
.navbar-expand .navbar-nav .nav-vertical-link-container a,
.navbar-expand .navbar-nav .nav-horizontal-link-container a{ font-size:0.8rem; }
.navbar-expand .navbar-nav .nav-link-container:hover .nav-link span,
.navbar-expand .navbar-nav .nav-link.active span{ border-bottom:0.063rem solid $gold-main; color: #fff; }

.nav-vertical{ background-color: $theme-primary; }
.nav-vertical-link-container:hover > a,
.nav-horizontal-link-container:hover > a{ background-color: $gold-hover; color: #ffffff; }
.nav-horizontal{ background-color: $theme-primary; }
.nav-vertical-link-container a{ color: $text-grey-link; }

p.accent-link a{ color: $gold-main; background: right center no-repeat url(assets/img/link-arrowright.svg); display: inline-block; padding-right: 1.625rem; font-size: 0.875rem; }
p.accent-link a:hover{ color: $gold-main; }

.organization:hover{ background-color:rgba(149, 140, 125, .3); }

.country-name{ font-size: 0.875rem; color: #fff; padding-top:0.313rem; }

@import "~bootstrap/scss/bootstrap";

$btn-primary-color: #ffffff;
$btn-primary: $theme-primary;

$btn-padding-y: 0.7rem;
$btn-padding-x: 2.2rem;
$btn-border-radius: 1.5rem;

.btn-primary {
    @include button-variant($btn-primary, darken($btn-primary, 7.5%), $btn-primary-color, lighten($btn-primary,5%), lighten($btn-primary, 10%), $btn-primary-color);
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
}
    
.btn-outline-primary {
    @include button-outline-variant($btn-primary, $btn-primary-color, $btn-primary, $btn-primary, $btn-primary-color);
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
    color:#fff;
}

h1{ margin-bottom: 0.3em; }
